import React, { Component } from 'react';
import CornerBadge from './corner-banner-badge';
import StatusLozenge from './status-lozenge';
import { Tooltip as ReactTooltip } from "react-tooltip";


class StatusHeader extends Component {

  /**
   * Setup the state.
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      hostname: this.props.hostname,
      online: this.props.online,
    }
  }

  /**
   * Render the StatusHeader component.
   */
  render() {
    return (
      <div>
        <CornerBadge />

        <div className="header-wrap">
          <img className="pt-5 app-logo" src={"/assets/obsidian.png"} alt="Minecraft" />
          <StatusLozenge online={this.state.online} />
        </div>

        <h1 className="mt-5 mb-5">
          <span role="img" aria-label="Pickaxe Emoji">⛏️</span> <span data-tooltip-id="copy-tooltip" className="click-copy" onClick={() => {navigator.clipboard.writeText(this.state.hostname)}}
          >{this.state.hostname}</span> <span role="img" aria-label="Pickaxe Emoji">⛏️</span>
        </h1>

        <ReactTooltip
          id="copy-tooltip"
          place="top"
          variant="info"
          content="Click to copy server address"
        />
        
      </div>
    )
  }
}
export default StatusHeader;