import React, { Component } from 'react';
import { RotateCircleLoading } from 'react-loadingg';

/** Import app components */
import StatusHeader from './components/status-header';
import MotdSection from './components/motd-section';
import AlertBanner from './components/alert-banner';
import OnlinePlayersTable from './components/online-players-table';
import ServerStatsBar from './components/server-stats-bar';
import AvailableCommands from './components/available-commands';

class App extends Component {

  serverHostname = 'obsidian.homesvr.online';

  constructor(props) {
    super(props);
    this.state = {
      serverStatus: [],
      isLoaded: false
    };
  }

  componentDidMount() {
    fetch('https://api.mcsrvstat.us/2/' + this.serverHostname)
      .then(res => res.json())
      .then((data) => {
        // Append the hostname as our defined one
        data.hostname = this.serverHostname;
        this.setState({
          isLoaded: true,
          serverStatus: data
        })
      })
  }

  render() {

    // App loading
    var { isLoaded } = this.state;
    if (!isLoaded) return (
      <main role="main" className="container">
        <RotateCircleLoading />
      </main>
    )
    const status = this.state.serverStatus;

    return (
      <div>
        <StatusHeader hostname={status.hostname} online={status.online} />

        {!status.online && <AlertBanner level="warning" message="Potential network issues detected" />}

        {status.online && <MotdSection motd={status.motd} />}

        <p className="lead pt-3">Vanilla survival server with a few quality of life mods {status.online && <span><code>{
          status.software} {status.version} v{status.protocol
          }</code>
          &nbsp;with <a target="blank" href="https://hangar.papermc.io/xenondevs/Nova">Nova</a> power</span>}</p>

        <div className="spacer-mini"></div>

        {status.online &&
          <p>
            <span className="badge badge-dark">{status.players.online}</span> players mining of out a total <span className="badge badge-dark">{status.players.max}</span>
          </p>
        }

        <div className="spacer-mini"></div>

        {status.online && <ServerStatsBar />}

        <p className="pt-4">We recommend either using <a target="blank" href="https://prismlauncher.org/">Prism Launcher</a> or using <a target="blank" href="https://tlauncher.org/en/">Tlauncher</a> and installing version <code>Fabric-{status.version}</code></p>

        <p>Get the <strong>current recommended</strong> mods and resources <a target="blank" href="https://drive.google.com/file/d/1vFNmN6tMP2KQZlovYL-TbPSsxoKW3gQX/view?usp=sharing">here</a>.</p>

        <p>If you would like to join, message <code>jitsz</code> on Discord to be whitelisted.</p>

        {status.online && status.players.online > 0 && <OnlinePlayersTable players={status.players.uuid} />}

        <AvailableCommands />

        <div className="spacer"></div>
      </div>
    );
  }
}
export default App;