import React, { Component } from 'react';

class MotdSection extends Component {

  /**
   * Setup the state.
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      motd: this.props.motd,
    }
  }

  /**
   * Render the MotdSection component.
   */
  render() {
    return (
      <div className="motd-wrap">
        {this.state.motd.html.map((motdLine, id) => (
          <div key={id} dangerouslySetInnerHTML={{ __html: motdLine }} />
        ))}
      </div>
    )
  }
}
export default MotdSection;