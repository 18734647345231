import React, { Component } from 'react';

class OnlinePlayersTable extends Component {
  /**
   * Setup the state.
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      players: this.props.players,
    }
  }

  /**
   * Render the OnlinePlayersTable component.
   */
  render() {
    return (
      <div className="pt-5 mb-5">
        <h3>Players Online</h3>
        <div className="players-online-wrap mt-3">
          <ul className="list-group">
            {Object.keys(this.state.players).map((player, i) => {
              return (
                <li className="list-group-item" key={i}>
                  <img className="avatar" alt={player} src={'https://minotar.net/avatar/'+ player} />
                  <a target="_blank" rel="noreferrer" href={'https://minecraftuuid.com/?search=' + player}>{player}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default OnlinePlayersTable;
