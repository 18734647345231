import React, { Component } from 'react';

class AvailableCommands extends Component {
  /**
   * Setup the state.
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      comamnds: {
        "/nova": "Shows the Nova help",
        "/nova items": "Shows the Nova items windows",
        "/simpletpa": "List SimpleTpa commands",
        "/tpatoggle": "Enables or disables your teleport status",
        "/tpa {user}": "Request a user teleports to you",
        "/tpahere {user}": "Request to teleport to a user",
        "/tpahereall": "Request to teleport everyone to you",
        "/back": "Teleports you to your last location",
        "/coreprotect": "Access the CoreProtect anti-grief tool",
      },
    }
  }

  /**
   * Render the AvailableCommands component.
   */
  render() {
    return (
      <div className="pt-5 mb-5">
        <h3>Available Commands</h3>
        <div className="pt-3 command-wrapper">
            {Object.keys(this.state.comamnds).map((command, i) => {
            return (
                <div className="row" key={i}>
                    <div className="column">
                        <div className="col-first">
                            <code>{command}</code>
                        </div>
                    </div>
                    <div className="column">
                        <div className="col-second">
                            {this.state.comamnds[command]}
                        </div>
                    </div>
                </div>
            );
            })}
        </div>
      </div>
    )
  }
}

export default AvailableCommands;
