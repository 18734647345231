import React, { Component } from 'react';

class ServerStatsBar extends Component {

  intervalID;

  constructor(props) {
    super(props);
    this.state = {
      systemStats: [],
      isLoaded: false
    };
  }

  // Fetch the stats from the diggerd API
  getData() {
    fetch('https://obsidian.homesvr.online/stats')
      .then(res => res.json())
      .then((data) => {
        this.setState({
          isLoaded: true,
          systemStats: data
        })
      })
  }

  componentDidMount() {
    this.getData();
    this.intervalID = setInterval(this.getData.bind(this), 6000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  /**
   * Render the ServerMemoryBar component.
   */
  render() {

    // If not loaded we render the empty state
    var { isLoaded } = this.state;
    if (!isLoaded) return (
      <div>
        <h5>System Usage</h5>
        <div className="progress progress-bar-striped progress-bar-animated progress-obsidian stats-memory-bar mt-2">
          <div className="progress-bar progress-bar-striped bg-success"
            role="progressbar" style={{ width: '0%' }}
            aria-valuenow="" aria-valuemin="0" aria-valuemax=""></div>
        </div>
        <small>0 %</small>

        <div className="progress progress-bar-striped progress-bar-animated progress-obsidian stats-memory-bar mt-2">
          <div className="progress-bar progress-bar-striped bg-success"
            role="progressbar" style={{ width: '0%' }}
            aria-valuenow="" aria-valuemin="0" aria-valuemax=""></div>
        </div>
        <small>0 GB</small>

        <div className="spacer-mini"></div>
      </div>
    )

    // Calculate the Memory usage
    const stats = this.state.systemStats;
    let widthMemPerc = 0;
    let widthCPUPerc = 0;

    // Calc the total memory size in GB, the used size and the percentage
    let totalMemoryGB = Number.parseFloat(stats.memory.total / 1073741824).toPrecision(2);
    let usedMemoryGB = Number.parseFloat(stats.memory.used / 1073741824).toPrecision(2);
    widthMemPerc = (usedMemoryGB / totalMemoryGB) * 100;

    // Calc the class to apply.
    // Green for below 84 yellow below 90 and red above that
    let progressMemClass = 'success';
    if (widthMemPerc >= 90 && widthMemPerc <= 94) {
      progressMemClass = 'warning';
    } else if (widthMemPerc >= 95) {
      progressMemClass = 'danger';
    }

    // Round the CPU usage
    widthCPUPerc = Math.round(stats.cpu.used_percent);

    let progressCPUClass = 'info';
    if (widthCPUPerc >= 60 && widthCPUPerc <= 90) {
      progressCPUClass = 'warning';
    } else if (widthCPUPerc >= 90) {
      progressCPUClass = 'danger';
    }

    // Return the rendered usage component
    return (
      <div className="server-stats-wrap">
        <h5>System Usage</h5>
        <div className="progress progress-bar-striped progress-bar-animated progress-obsidian stats-cpu-bar mt-2">
          <div className={"progress-bar progress-bar-striped bg-" + progressCPUClass}
            role="progressbar" style={{ width: widthCPUPerc + '%' }}
            aria-valuenow={{ widthCPUPerc }} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <small>{widthCPUPerc} %</small>

        <div className="progress progress-bar-striped progress-bar-animated progress-obsidian stats-memory-bar mt-2">
          <div className={"progress-bar progress-bar-striped bg-" + progressMemClass}
            role="progressbar" style={{ width: widthMemPerc + '%' }}
            aria-valuenow={{ usedMemoryGB }} aria-valuemin="0" aria-valuemax={{ totalMemoryGB }}></div>
        </div>
        <small>{usedMemoryGB} / {totalMemoryGB} GB</small>
        
        <p className="pt-3">
          <small>Server uptime: {stats.host.uptime_friendly}</small>
        </p>
      </div>
    )
  }
}
export default ServerStatsBar;