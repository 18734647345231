import React, { Component } from 'react';

class CornerBadge extends Component {

  /**
   * Render the CornerBadge component.
   */
  render() {
    return (
      <div className="corner-badge">
        <a className="corner-badge-link" target="_blank" rel="noreferrer" href="http://obsidian.homesvr.online:8100/">Server Map</a>
      </div>
    )
  }
}
export default CornerBadge;