import React, { Component } from 'react';

class StatusLozenge extends Component {

  /**
   * Setup the state.
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      online: this.props.online,
    }
  }

  /**
   * Render the MotdSection component.
   */
  render() {
    // Set the online lozenge and a few defaults
    let onlineLozenge;
    if (this.props.online) {
      onlineLozenge = <span className="badge badge-success">Online</span>;
    } else {
      onlineLozenge = <span className="badge badge-danger">Offline</span>;
    }

    return (
      <div className="status-lozenge">
        {onlineLozenge}
      </div>
    )
  }
}
export default StatusLozenge;