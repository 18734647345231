import React, { Component } from 'react';

class AlertBanner extends Component {

  /**
   * Setup the state.
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      class: `alert alert-${this.props.level}`,
      message: this.props.message,
    }
  }

  /**
   * Render the AlertBanner component.
   */
  render() {
    return (
      <div className={this.state.class} role="alert">
        {this.state.message}.
      </div>
    )
  }
}
export default AlertBanner;